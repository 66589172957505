import React, { useState } from "react";
import { graphql } from 'gatsby';

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Col, Row, Modal } from "antd";
// import scrollTo from "gatsby-plugin-smoothscroll";
import siv from "scroll-into-view";

import { SEO, Layout, Footer } from "../components";
import { HomeHeader } from "../components/Headers/HomeHeader";
import "../styles/landing.scss";

import inspectIcon from "../images/inspect.svg";
import collectIcon from "../images/collect.svg";
import transportIcon from "../images/transport.svg";
import sellIcon from "../images/sell.svg";
import introVideoMp4 from "../videos/gpm-video.mp4";
import introVideoWebm from "../videos/gpm-video.webm";
import RightArrow from "../components/icons/RightArrow";

import welcomeBg from "../images/welcome-sec-bg.webp";
import welcomeBgPng from "../images/welcome-sec-bg.png";

import { ContactUsForm } from "../components/common/ContactUsForm";

const scrollTo = (id: string) => {
  const el: HTMLElement | null = document.getElementById(id.slice(1));
  siv(el, { align: { top: 0, topOffset: 90 } });
};

const video: any = React.createRef();

type PageProps = {
  data: {
    contentfulPage: {
      title: string,
      seoDescription: string,
      socialShareThumbnail: {
        file: {
          url: string
        }
      }
    }
  }
}

const IndexPage = ({ data }: PageProps) => {
  const { title, seoDescription, socialShareThumbnail } = data.contentfulPage;
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [played, setPlayed] = useState(false);

  const handleCloseVideo = () => {
    setVideoModalVisible(false);
    video.current.pause();
    video.current.currentTime = 0;
    setPlayed(true);
  };

  const handleOpenvideoPopup = () => {
    setVideoModalVisible(true);
    if (played) {
      video.current.play();
    }
  };

  return (
    <Layout header={<HomeHeader style={{ marginBottom: 0 }} />}>
      <SEO
        title={title}
        desc={seoDescription}
        image={socialShareThumbnail.file.url}
      />

      {/* Start Hero Section */}
      <section className="hero">
        <div className="hero__bg">
          <StaticImage
            src="../images/gpm-hero-bg.png"
            style={{ height: "100%" }}
            alt=""
            role="presentation"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp"]}
          />
        </div>
        <div className="hero__content">
          <h1 className="hero__content-title">
            YOUR DOORWAY <br />
            TO WEALTH FREEDOM
          </h1>
          <p className="hero__content-subtitle">bespoke solutions</p>
          <span>Just for you</span>
          <div className="d-flex hero__content-btns">
            <Link
              to="/register/"
              className="btn btn-primary hero__content-btn"
              id="check-eligib"
            >
              CHECK YOUR ELIGIBILITY <RightArrow />
            </Link>
            <button
              className="btn btn-outline hero__content-btn--outline"
              onClick={() => handleOpenvideoPopup()}
            >
              Watch Video <RightArrow color="#bda06d" />
            </button>
          </div>
        </div>
        <Modal
          visible={videoModalVisible}
          onCancel={() => {
            handleCloseVideo();
          }}
          footer={null}
          className="video-modal"
          centered
        >
          {/* <div
            style={{
              position: "absolute",
              right: 0,
              color: "rgb(41, 50, 56)",
              lineHeight: "1rem",
              padding: "1rem",
              cursor: "pointer",
              top: 0,
            }}
          >
            ×
          </div> */}

          <video autoPlay playsInline ref={video} controls>
            <source src={introVideoMp4} type="video/mp4" />
            <source src={introVideoWebm} type="video/webm" />
          </video>
        </Modal>
      </section>

      {/* End Hero Section */}
      {/* Start Beginning Section */}

      <section className="beginning">
        <Row>
          <Col xs={24} md={12}>
            <div className="beginning__intro">
              <h2 className="block-title">THIS IS WHERE IT BEGINS</h2>
              <p className="block-info">
                A PATH TO AN ELITE COMMUNITY THAT <br /> EXISTS OUTSIDE OF
                UNCERTAINTY.
              </p>
              <p>All you have to do is step through</p>
              <button
                className="btn btn-outline beginning__btn-outline"
                onClick={() => scrollTo("#welcome")}
              >
                find out more <RightArrow color="#020202" />
              </button>
            </div>
            <Row className="beginning__imgs-wrapper">
              <Col xs={24} md={12}>
                <div className="beginning__gold-freedom">
                  <div
                    style={{
                      position: "relative",
                      zIndex: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      scrollTo("#real-wealth");
                    }}
                  >
                    <h3>
                      Gold <br /> is freedom
                    </h3>
                    <p>and security</p>
                    <button
                      className="btn btn-primary beginning__btn-primary"
                      onClick={() => scrollTo("#real-wealth")}
                    >
                      find out more <RightArrow color="#fff" />
                    </button>
                  </div>
                  <div className="beginning__bg-img">
                    <StaticImage
                      src="../images/img-freedom.png"
                      style={{ zIndex: 1, height: "100%" }}
                      alt=""
                      role="presentation"
                      placeholder="blurred"
                      layout="fullWidth"
                      formats={["auto", "webp"]}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="beginning__bespoke">
                  <div
                    style={{
                      position: "relative",
                      zIndex: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3>
                      Bespoke <br /> solutions
                    </h3>
                    <p>Just for you</p>
                    <button
                      className="btn btn-primary beginning__btn-primary"
                      onClick={() => scrollTo("#individual-forms")}
                    >
                      find out more <RightArrow color="#fff" />
                    </button>
                  </div>
                  <div className="beginning__bg-img">
                    <StaticImage
                      src="../images/img-bespoke.png"
                      style={{ zIndex: 1, height: "100%" }}
                      alt=""
                      role="presentation"
                      placeholder="blurred"
                      layout="fullWidth"
                      formats={["auto", "webp"]}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <div className="beginning__where">
              <div className="beginning__where-box">
                <h2 className="text-uppercase">You decide where and when</h2>
                <p>We show you how</p>
              </div>
              <div className="beginning__where-bg">
                <StaticImage
                  src="../images/when-and-where.png"
                  style={{ height: "100%" }}
                  alt=""
                  role="presentation"
                  placeholder="blurred"
                  layout="fullWidth"
                  formats={["auto", "webp"]}
                />
              </div>
            </div>
            <div className="beginning__countries">
              <Row style={{ flexWrap: "nowrap" }}>
                <Col md={8} style={{ flex: "1 1 auto" }}>
                  {" "}
                  <div className="beginning__countries-img">
                    <StaticImage
                      src="../images/switzrland.png"
                      alt="Night view of city in Switzerland"
                      placeholder="blurred"
                      style={{ height: "400px" }}
                      layout="fullWidth"
                      objectFit="cover"
                      formats={["auto", "webp"]}
                    />
                    <h4>switzerland</h4>
                  </div>
                </Col>
                <Col md={8} style={{ flex: "1 1 auto" }}>
                  <div className="beginning__countries-img">
                    <StaticImage
                      src="../images/hongkong.png"
                      alt="Night view of city in Hong Kong"
                      placeholder="blurred"
                      style={{ height: "400px" }}
                      layout="fullWidth"
                      objectFit="cover"
                      formats={["auto", "webp"]}
                    />
                    <h4>Hongkong</h4>
                  </div>
                </Col>
                <Col md={8} style={{ flex: "1 1 auto" }}>
                  <div className="beginning__countries-img">
                    <StaticImage
                      src="../images/singapore.png"
                      alt="Night view of city in Singapore"
                      placeholder="blurred"
                      style={{ height: "400px" }}
                      layout="fullWidth"
                      objectFit="cover"
                      formats={["auto", "webp"]}
                    />
                    <h4>singapore</h4>
                  </div>
                </Col>
                <div className="beginning__countries-content">
                  <div style={{ flex: 1 }}>
                    <h2>WE ARE GLOBAL</h2>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn beginning__btn-black"
                      onClick={() => scrollTo("#global-map")}
                    >
                      <div style={{ flex: "1 1 auto" }}>find out more</div>{" "}
                      <RightArrow color="#fff" />
                    </button>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
      {/* End Beginning Section */}

      {/* Start doorway section */}
      <section className="doorway">
        <div className="doorway__hero-bg">
          <StaticImage
            src="../images/gpm-hero-bg.png"
            style={{ height: "100%" }}
            alt=""
            role="presentation"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp"]}
          />
        </div>
        <Row>
          <Col xs={24} md={12}>
            <div className="doorway__block">
              <div className="container">
                <h2 className="block-title">THIS IS WHERE IT BEGINS</h2>
                <p className="block-info">
                  A PATH TO AN ELITE COMMUNITY THAT <br /> EXISTS OUTSIDE OF
                  UNCERTAINTY.
                </p>
                <p className="doorway__info">
                  Where you can enjoy complete financial freedom and confidence.
                  Where knowledge and expertise create bespoke solutions just
                  for you. <br />
                  You tell us what and where, and we show you how. There are
                  possibilities beyond your expectations. We can show you a
                  doorway that will lead you to new experiences of wealth
                  freedom. All you have to do is step through. <br />
                  Global Precious Metals. Your Doorway to Wealth Freedom.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="doorway__right-block">
              <div className="doorway__bg">
                <h2>
                  YOUR DOORWAY <br />
                  TO WEALTH FREEDOM
                </h2>
                <p className="doorway__subtitle">bespoke solutions</p>
                <p className="doorway__note">Just for you</p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {/* End doorway section */}

      {/* Start Welcome section */}
      <section className="welcome" id="welcome">
        <Row>
          <Col xs={24} md={7}>
            <h2 className="welcome__title">WELCOME</h2>
            <p className="block-info">
              GLOBAL PRECIOUS METALS SERVICES ARE FOR DISCERNING INVESTORS
              {/* WHO
              SHARE THE <br /> SAME IDEALS OF PRESERVING WEALTH <br /> AND
              FREEDOM IN UNCERTAIN TIMES.{" "} */}
            </p>
            <p className="welcome__info">
              We have deep roots in Singapore <br /> and Switzerland, and are
              the only <br />
              truly global firm of its kind. <br />
              Proudly, we honour our <br /> multigenerational Singapore <br />{" "}
              heritage and Swiss roots, <br /> upholding values of individual{" "}
              <br />
              rights, perfectionist diligence and <br /> enduring solidity.
            </p>
          </Col>
          <Col
            xs={24}
            md={5}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              zIndex: -1,
            }}
          >
            <picture>
              <source
                className="welcome__bg-img"
                role="presentation"
                srcSet={welcomeBg}
                type="image/webp"
              />
              <img
                className="welcome__bg-img"
                alt=""
                role="presentation"
                src={welcomeBgPng}
              />
            </picture>
          </Col>
          <Col xs={24} md={12} style={{ paddingLeft: 16 }}>
            <h2 className="welcome__gold-title">
              GOLD IS <br />
              THE MONEY <br />
              OF KINGS
              <div>NORM FRANZ</div>
            </h2>

            <div className="welcome__blocks">
              <div className="welcome__block">
                <h3 className="welcome__block-title">WEALTH PROTECTION</h3>
                <p className="welcome__block-info">
                  Diversification benefits come with the freedom of true
                  ownership, private property held with us has no small print.
                </p>
              </div>
              <div className="welcome__block">
                <h3 className="welcome__block-title">GLOBAL EXECUTION</h3>
                <p className="welcome__block-info">
                  You decide where and when, we deal with how. How you execute
                  your specific gold strategy, and its role in your broader
                  portfolio.
                </p>
              </div>
              <div className="welcome__block">
                <h3 className="welcome__block-title">WEALTH PROTECTION</h3>
                <p className="welcome__block-info">
                  GPM is part of a larger group (NIMOI) which has been based in
                  Singapore since the 1950’s, and is inspired by a long Swiss
                  private banking tradition. We have the resources, systems,
                  talent and compliance experience to support generational
                  ambitions.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {/* End Welcome section */}

      {/* Start Freedom section */}
      <section className="freedom" id="real-wealth">
        <div className="freedom__bg">
          <StaticImage
            src="../images/freedom-bg.png"
            style={{ height: "100%" }}
            alt=""
            role="presentation"
            placeholder="blurred"
            layout="fullWidth"
            formats={["auto", "webp"]}
          />
        </div>
        <h2 className="freedom__title">
          REAL WEALTH IS FOUND WITH FREEDOM. FREEDOM IS FOUND WITH GOLD. GET
          MORE OUT OF GOLD THAN ANY ASSET CLASS.
        </h2>
        <Row gutter={50}>
          <Col xs={24} md={8}>
            <h3 className="freedom__subtitle">
              FULL <br /> OWNERSHIP
            </h3>
            <p className="freedom__info">
              At GPM, you can expect full ownership. gold is your gold. Its has
              no counterparty risk. It is your property, and we protect it -
              nothing else. You take delivery, move it, touch it or sell it
              seamlessly anywhere in the world. Yours, without reservations; we
              will store, sell it, and deliver it anywhere for immediate
              liquidity.
            </p>
          </Col>
          <Col xs={24} md={8}>
            <h3 className="freedom__subtitle">
              FLUID <br /> AND PRIVATE
            </h3>
            <p className="freedom__info">
              Gold is an asset unlike any other. It is the oldest monetary asset
              in the financial firmament, and today still commands a position
              that sits above all others.
            </p>
          </Col>
          <Col xs={24} md={8}>
            <h3 className="freedom__subtitle">
              SHELTERED FROM SYSTEMIC FAILURE RISK
            </h3>
            <p className="freedom__info">
              There is no counterparty risk, no credit risk, no comingling. That
              part of your wealth is protected from others’ mismanagement and
              extreme tail risks.
            </p>
          </Col>
        </Row>
      </section>
      {/* End Freedom section */}
      {/* Start financial Freedom section */}
      <section className="financial-freedom">
        <h2 className="financial-freedom__title">
          YOUR FINANCIAL FREEDOM IS THIS SIMPLE. YOU TELL US ‘WHAT’, WE WILL
          TAKE CARE OF HOW.
        </h2>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <h3 className="financial-freedom__subtitle">DEDICATED TEAM</h3>
            <p className="financial-freedom__info">
              At GPM, We provide a dedicated client support team with decades of
              experience. They ensure thorough and intelligent KYC/AML processes
              are followed, and reviewed by third-party auditors.
            </p>
          </Col>
          <Col xs={24} md={8}>
            <h3 className="financial-freedom__subtitle">SWISS-STANDARDS</h3>
            <p className="financial-freedom__info">
              Our swiss-standards of client information stewardship are enforced
              on self-managed IT infrastructure & services.
            </p>
          </Col>
          <Col xs={24} md={8}>
            <h3 className="financial-freedom__subtitle">DIVERSE CLIENTELE</h3>
            <p className="financial-freedom__info">
              We work with a variety of clients, with experience supporting
              individual, corporate and trusts with their investments.
            </p>
          </Col>
        </Row>
      </section>
      <div className="financial-freedom__types" id="individual-forms">
        <div className="financial-freedom__types-bg">
          <StaticImage
            src="../images/financial-freedom-bg.png"
            style={{ height: "100%" }}
            alt=""
            role="presentation"
            placeholder="tracedSVG"
            layout="fullWidth"
            formats={["auto", "webp"]}
            objectFit="cover"
          />
        </div>
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: 500 }}
        >
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            xs={24}
            md={8}
          >
            <div className="financial-freedom__types-icon">
              <div className="financial-freedom__types-icon-bg">
                <StaticImage
                  src="../images/individual-icon.svg"
                  alt=""
                  role="presentation"
                  placeholder="tracedSVG"
                  formats={["auto", "webp"]}
                />
              </div>
              <div className="financial-freedom__types-icon-text">
                INDIVIDUAL
              </div>
            </div>
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            xs={24}
            md={8}
          >
            <div className="financial-freedom__types-icon">
              <div className="financial-freedom__types-icon-bg">
                <StaticImage
                  src="../images/corporate-icon.svg"
                  alt=""
                  role="presentation"
                  placeholder="tracedSVG"
                  formats={["auto", "webp"]}
                />
              </div>
              <div className="financial-freedom__types-icon-text">
                CORPORATE
              </div>
            </div>
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            xs={24}
            md={8}
          >
            <div className="financial-freedom__types-icon">
              <div className="financial-freedom__types-icon-bg">
                <StaticImage
                  src="../images/trust-icon.svg"
                  alt=""
                  role="presentation"
                  placeholder="tracedSVG"
                  formats={["auto", "webp"]}
                />
              </div>
              <div className="financial-freedom__types-icon-text">TRUST</div>
            </div>
          </Col>
        </Row>
      </div>
      {/* End financial Freedom section */}
      {/* Start Choise section */}
      <section className="choice">
        <Row>
          <Col xs={24} md={12}>
            <div className="choice__block">
              <h2 className="choice__title">
                CHOOSE YOUR <br /> METAL
              </h2>
              <p className="choice__info">
                Gold, Silver, Platinum, and Palladium <br />
                We offer access to the most reputable LBMA brands.
              </p>
            </div>
            <div className="choice__block">
              <h2 className="choice__title">
                CHOOSE YOUR <br /> VAULT
              </h2>
              <p className="choice__info">
                Located in free trade zones around the word. View and <br />{" "}
                withdraw your metals at your convenience.
                <br />
                Fully insured storage and transport.
              </p>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={12} style={{ marginBottom: 20 }}>
              <Col xs={24} md={12}>
                <div className="choice__steps">
                  <div className="choice__steps-icon">
                    <img src={inspectIcon} alt="" role="presentation" />
                  </div>
                  <h4 className="choice__steps-title">INSPECT</h4>
                  <p className="choice__steps-info">
                    Personally come and inspect your assets at our storage
                    facility
                  </p>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="choice__steps">
                  <div className="choice__steps-icon">
                    <img src={collectIcon} alt="" role="presentation" />
                  </div>
                  <h4 className="choice__steps-title">COLLECT</h4>
                  <p className="choice__steps-info">
                    Collect your assets directly or through an appointed party
                    when needed.
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <div className="choice__steps">
                  <div className="choice__steps-icon">
                    <img src={transportIcon} alt="" role="presentation" />
                  </div>
                  <h4 className="choice__steps-title">TRANSPORT</h4>
                  <p className="choice__steps-info">
                    Transport your assets by a fully insured shipment to
                    selected countries.
                  </p>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="choice__steps">
                  <div className="choice__steps-icon">
                    <img src={sellIcon} alt="" role="presentation" />
                  </div>
                  <h4 className="choice__steps-title">SELL</h4>
                  <p className="choice__steps-info">
                    Sell your assets back to us at any time.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      {/* End Choise section */}
      {/* Start Global map section */}
      <section className="global-map" id="global-map">
        <div className="global-map__bg">
          <StaticImage
            src="../images/gloabl-map.png"
            style={{ height: "100%" }}
            alt=""
            role="presentation"
            placeholder="tracedSVG"
            layout="fullWidth"
            formats={["auto", "webp"]}
            objectFit="contain"
          />
        </div>
        <h2 className="welcome__title">
          WE ARE <br />
          GLOBAL
        </h2>
        <p className="global-map__info">
          WITH OFFICES IN SWITZERLAND, SINGAPORE AND HONG KONG; VAULTS IN SECURE
          LOCATIONS THROUGHOUT THE WORLD.
        </p>
        <div className="landing-container global-map__blocks-up">
          <Row gutter={100}>
            <Col xs={24} md={8}>
              <div className="global-map__block">
                <h4 className="global-map__block-title">SALES & TRADING</h4>

                <p className="global-map__block-info">
                  Freshly cast bars and fully traceable, unbroken chain of
                  custody. 2-way immediate liquidity on spot price with
                  transparent, all-inclusive fees
                </p>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className="global-map__block">
                <h4 className="global-map__block-title">CUSTODY</h4>

                <p className="global-map__block-info">
                  Free-trade bonded facilities allow frictionless and
                  tax-efficient monetization and movement. Full insurance
                  coverage in maximum security vaults
                </p>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className="global-map__block">
                <h4 className="global-map__block-title">LOGISTICS</h4>

                <p className="global-map__block-info">
                  25+ countries network and experience in local legal, tax and
                  banking environment
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* End Global map section */}
      {/* Start Contact Section */}
      <section id="contact-form">
        <ContactUsForm style={{ minHeight: 100 }} />
      </section>
      <Footer />
      {/* End Footer Section */}
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    contentfulPage(slug: {eq: "home"}) {
      title
      seoDescription
      socialShareThumbnail {
        file {
          url
        }
      }
    }
  }
`

export default IndexPage;
